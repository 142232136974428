<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon icon-left">{{t('10030')}}</i>
			</a>
		</div>
	</nav>
	<!-- //nav -->
	<div id="container" class="container">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub find">
			<!-- contact -->
			<section class="content">
				<div class="row">
					<div class="inner">
						<div class="inner__top">
							<h2 class="title">
								{{t('10229')}}
							</h2>
						</div>
						<div class="inner__wrap">
							<div class="frms">
								<div class="frms__item">
									<h3 class="frms__title">{{t('10037')}}</h3>
									<div class="frms__wrap">
										<input type="tel" class="ipt-frm" v-model="phoneNumber" @input="updatePhoneNumber($event)" @keyup="checkNumber" maxlength="13" :disabled="isSended">
										<div class="frms__btns">
											<button type="button" class="btn-frmreset" v-show="phoneNumber.length > 0" @click="reset" :disabled="isSended">
												<i class="icon icon-closesmall">{{t('10036')}}</i>
											</button>
											<button type="button" class="btn btn-medium btn-secondary" :class="{'active' : isPhoneNumber && !isSended}" @click="onClickSendPhoneNumber">{{t('10122')}}</button>
										</div>
									</div>
								</div>
								<!-- [D[ 인증번호 전송시 노출 -->
								<div class="frms__item" v-show="isSended">
									<h3 class="frms__title">{{t('10124')}}</h3>
									<div class="frms__wrap">
										<input type="text" pattern="[0-9]*" inputmode="numeric" class="ipt-frm" :placeholder="t('10779')" :value="verifyCode" @input="event => verifyCode = event.target.value"><!-- 재번역 체크 -->
										<div class="frms__btns">
											<span class="frms__time" :style="{'color' : phoneValidateTime <= 0 ? '#c4c4c4' : ''}">
												{{String(Math.trunc(phoneValidateTime / 60)).padStart(2, "0")}}:{{String(phoneValidateTime % 60).padStart(2, "0")}}
											</span>
											<button type="button" class="btn btn-medium btn-secondary active" @click="reSend">{{t('10125')}}</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder">
		<div class="btns btns--centered">
			<button type="button" class="btn" :class="verifyCode.toString().length >= 5? 'btn-confirm' : 'btn-disabled'" @click="verify">{{t('10229')}}</button>
		</div>
	</div>
	<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
	<TheNavigation></TheNavigation>
</template>

<script>
import { useRouter } from 'vue-router';
import { ref, computed } from "vue"
import { useStore } from "vuex"
import usePhoneValidate from '@/composables/usePhoneValidate';
import { checkPhoneNumber } from '@/utils/formatter';
import ErrorAlert from "@/components/layers/Message_alert";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

export default {
	components: {
		ErrorAlert,
	},
	setup() {
		const router = useRouter();
		const store = useStore();

		const email = computed(() => store.state.mmb03.mmb03);
		const openErrorAlert = ref(false)
		const verifyCode = ref('');
		const phoneNumber = ref("");
		const isPhoneNumber = ref(false);
		const isSended = ref(false);
		const isFailed = ref(false); 

		const {
			phoneValidateTime,
			onClickSendPhoneNumber,
			serverPhoneValidateNumber
		} = usePhoneValidate("03", email?.value[0]?.mem_id);

		const checkNumber = () => {
			const [number, isValid] = checkPhoneNumber(phoneNumber.value);
			phoneNumber.value = number;
			store.commit("mmb03/SET_PASSWORD_PHONE_NUMBER", {phoneNumber2: number});
			isPhoneNumber.value = isValid;
			console.log(isPhoneNumber.value);
		}
		
		const updatePhoneNumber = (e) => {
			store.commit('mmb03/SET_PASSWORD_PHONE_NUMBER', {phoneNumber2: e.target.value});
		};

		const verify = () => {
			if(isSended.value === false){
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: "인증번호를 요청해주세요."});
				openErrorAlert.value = true;
				//alert("인증번호를 요청해주세요.");
				return;
			}
			if(serverPhoneValidateNumber.value !== verifyCode.value.toString()) {
				//alert("인증 번호가 일치하지 않습니다. 다시 확인해주세요.");
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: "인증 번호가 일치하지 않습니다. 다시 확인해주세요."});
				openErrorAlert.value = true;
				isFailed.value = true;
				return;
			}
			if(phoneValidateTime.value === 0){
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: "입력시간을 초과하였습니다. 재전송 버튼을 눌러주세요."});
				openErrorAlert.value = true;
				//alert("입력시간을 초과하였습니다. 재전송 버튼을 눌러주세요.");
				return;
			}
			if(serverPhoneValidateNumber.value === verifyCode.value.toString()){
				router.push("/member/find/password/step3");
			}
		}

		const reset = () => {
			store.commit("mmb03/SET_PASSWORD_PHONE_NUMBER", {phoneNumber2: ""});
			phoneNumber.value = "";
			isPhoneNumber.value = false;
		}

		const goBack = () => {
			router.go(-1);
		};
		const { t }= useI18n() //번역필수 모듈

		return {
			goBack,
			checkNumber,
			verify,
			phoneNumber,
			onClickSendPhoneNumber: async () => {
				try {
					await onClickSendPhoneNumber(phoneNumber.value);
					isSended.value = true;
				}catch(e) {
					isSended.value = false;
					console.error(e);
				}
			},
			isFailed,
			isSended,
			verifyCode,
			phoneValidateTime,
			openErrorAlert,
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
			updatePhoneNumber,
			reset,
			reSend: () => {
				if(phoneValidateTime.value > 0){
					//alert("인증번호를 재발송 하였습니다. 인증번호가 오지 않는 경우 입력하신 정보를 확인해주세요.");
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "인증번호를 재발송 하였습니다. 인증번호가 오지 않는 경우 입력하신 정보를 확인해주세요."});
					openErrorAlert.value = true;
				}else {
					//alert("인증번호를 재발송 하였습니다.");
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "인증번호를 재발송 하였습니다."});
					openErrorAlert.value = true;
				}
				onClickSendPhoneNumber(phoneNumber.value);
			},
			isPhoneNumber,
			t,
			i18n,
		}
	},
}
</script>
